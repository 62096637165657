import { createRouter, createWebHistory } from 'vue-router'
import { verifyJWT } from '../functions/axios'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: { index: 0 },
    component: () => import('../views/homePage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { index: 1 },
    component: () => import('../views/login.vue'),
  },
  {
    path: '/lists',
    name: 'lists',
    meta: { index: 2 },
    component: () => import('../views/list.vue'),
  },
  {
    path: '/gallery',
    name: 'gallery',
    meta: { index: 4 },
    component: () => import('../views/galleryView.vue'),
  },
  {
    path: '/echarts',
    name: 'echarts',
    meta: { index: 5 },
    component: () => import('../views/echarts.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { index: 99 },
    component: () => import('../views/signUp.vue'),
  },
  {
    path: '/error',
    name: 'error',
    meta: { index: 100 },
    component: () => import('../views/error.vue'),
    // props:true
  },
  {
    path: '/services',
    name: 'services',
    // props:true,
    children: [
      {
        path: '',
        name: 'listservices',
        meta: { index: 101 },
        component: () => import('../views/serviceList.vue'),
      },
      {
        path: '/services/addservice',
        name: 'addservice',
        meta: { index: 102 },
        component: () => import('../views/addService.vue'),
      },
      {
        path: '/services/detail',
        name: 'servicedetail',
        meta: { index: 103 },
        props:true,
        component: () => import('../views/serviceDe.vue'),
      },
    ],
  },
  // {
  //   path: '/addservice',
  //   name: 'addservice',
  //   meta: { index: 101 },
  //   component: () => import('../views/addService.vue'),
  //   // props:true
  // },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
console.log('createRouteDone')

const jwtPaths = ['/lists', '/gallery','/services','/services/addservice', '/services/detail']
const paths = [
  '/lists',
  '/services',
  '/services/addservice',
  '/services/detail',
  '/login',
  '/gallery',
  '/echarts',
  '/',
  '/signup',
  '/error',
]
router.beforeEach(async (to, from) => {
   //判断，如果用户是
   console.log(from.name,'routeFrom')
  // console.log('路由前置守卫启动')
  if (!paths.includes(to.path)) {
    alert('功能开发中')
    return '/services'
  } else {
    if (jwtPaths.includes(to.path)) {
      const res = (await verifyJWT()) as any
      if (res == 'session验证成功') {
        return true
      } else {
        alert('缓存验证失败，请登录')
        return '/login'
      }
    } else {
      return true
    }
  }
})

export default router
