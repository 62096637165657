import './assets/css/bootstrap.min.css'
import './assets/css/main.css'
import './assets/fonts/icomoon/icomoon.css'
import './assets/css/dragula/dragula.css'
import './assets/css/gallery.css'
import 'animate.css'

import './assets/js/jquery.js'
import './assets/js/bootstrap.min.js'
import './assets/js/scrollup/jquery.scrollUp.js'
import './assets/js/dragula/dragula.min.js' //这个一定要优先加载

// import './assets/js/gallery/baguetteBox.js'
// import './assets/js/gallery/plugins.js'
// import './assets/js/gallery/custom-gallery.js'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia().use(piniaPluginPersistedstate))
console.log('mainUseRouter')
app.use(router)

app.mount('#app')
