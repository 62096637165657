import axios from 'axios'
import { url, rawurl } from './static'
import type { listData, SignUp, Products } from '../types/listData'
axios.defaults.withCredentials = true
// axios.defaults.headers['X-From-Cdn']='wilbur'
export const getData = async () => {
  const res = await axios.get(url)
  return res.data
}

export const addDoc = async (data: listData) => {
  const res = await axios.post(url + '/addOne', data)
  return res.data
}

export const deleteOne = async (id: string) => {
  const res = await axios.post(url + '/deleteOne', { _id: id })
  return res.data
}

export const updateOne = async (data: listData & { _id: string }) => {
  try {
    const res = await axios.post(url + '/update', data)
    return res.data
  } catch (e) {
    return e
  }
}

export const getJWT = async (name: string, passwd: string) => {
  try {
    //用户名认证，获取cookie中jwt字段,!!!验证用户名后，没有获取jwt???
    const res = await axios.post(rawurl + '/auth/login', { name, passwd })
    console.log(res, 'jwt')
    return res.data.data
  } catch (e) {
    console.log(e,'login jwt错误')
    return e
  }
}

export const verifyJWT = async () => {
  try {
    const res = await axios.post(rawurl + '/auth/verifyjwt', {})
    console.log(res.data.data)
    if (res.data.data == 'session验证成功') {
      return res.data.data
    }
  } catch (e) {
    console.error(e + 'jwt验证失败')
    return e
  }
}

export const signUp = async (data: SignUp) => {
  const res = await axios.post(rawurl + '/auth/sign', data)
  return res.data.data
}

export const addProducts = async (data: Products) => {
  const res = await axios.post(rawurl + '/products/addone', data)
  return res.data.data
}

export const searchProducts = async (data: any[]) => {
  try {
    const res = await axios.post(rawurl + '/products/search', { data })
    const doc = res.data.data as any[]
    if (doc.length > 0) return doc
  } catch (e) {
    console.log(e)
    return '未查到数据'
  }
}

export const updateProducts = async (data: Products) => {
  const res = await axios.post(rawurl + '/products/update', data)
  console.log(res, 'update')
  try {
    const doc = res.data.data as any
    if (doc) return doc
  } catch (e) {
    console.log(e)
    return '更新未成功'
  }
}

export const listProducts = async () => {
  try {
    const res = await axios.get(rawurl + '/products')
    console.log(res.data,'products')
    const doc = res.data.data as any
    if (doc) return doc
  } catch (e) {
    console.log(e)
    return e
  }
}
